.log-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.log-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 25px;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

/* Genel input ve select ayarları */
input[type="text"],
select {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
    width: 100%;
    background-color: #fff;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><polygon points="0,0 10,0 5,5" fill="%23000"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
}

input[type="text"]:focus,
select:focus {
    border-color: #007bff;
    outline: none;
}

/* Versiyon ve User ID inputlarındaki ok ikonlarını kaldırıyoruz */
.input-with-tags input,
.user-id-input {
    background-image: none !important;
}

/* Datepicker ve preset butonları */
/* Mevcut .date-picker-container ayarını revize ediyoruz */
.date-picker-container {
    display: flex;
    align-items: center; /* Dikeyde ortalamak için değiştirildi */
    gap: 20px; /* Soldaki takvim ile sağdaki kapsayıcı arasındaki boşluk */
}
  
  /* Sağ tarafı dikeyde sıralamak için */
  .right-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* 3 sütunluk grid yapısı: 6 buton 2 satıra dağılır */
  .preset-buttons {
    display: grid;
    grid-template-columns: repeat(3, minmax(120px, 1fr));
    gap: 10px;
    margin-top: 0; /* İsteğe göre ayarlayabilirsiniz */
  }
  
  /* Butonların stilini koruyor, sadece konumlarını düzenliyoruz */
  .preset-buttons button {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #007bff;
    border-radius: 5px;
    background-color: #fff;
    color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }
  
  .preset-buttons button:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  /* Reset butonunda özel pembe tonlar */
  .reset-button {
    border-color: #ff69b4;
    color: #ff69b4;
  }
  .reset-button:hover {
    background-color: #ff69b4;
    color: #fff;
  }
  
  /* Tarih aralığı bilgileri yan yana iki hücrede */
  .date-range-display {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  
  /* Her bir tarih kutusu */
  .date-item {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    color: #333;
  }

.date-range-text {
    text-align: right;
}

.run-button {
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.run-button:hover {
    background-color: #0056b3;
}

/* Versiyon alanı: input ve tag'ler aynı satırda */
.input-with-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
}

.input-with-tags input {
    flex: 1;
    border: none;
    outline: none;
    min-width: 120px;
    padding: 5px;
    font-size: 16px;
}

/* Ortak tag stili */
.tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
}

.tag {
    background-color: #e2e1e1;
    color: #000;
    border-radius: 3px;
    padding: 3px 8px;
    margin: 3px 5px 3px 0;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.tag button {
    background: transparent;
    border: none;
    border-radius: 2px;
    color: #000;
    margin-left: 5px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
}

.tag button:hover {
    background-color: pink;
    color: red;
}

/* Popup overlay - covers entire screen */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* make sure it appears on top */
}

/* Popup content box */
.popup-content {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    min-width: 600px;
}

.popup-content h2 {
    font-size: 24px;
    margin: 0;
}