/* src/styles/global.css */

.home-container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background: radial-gradient(50% 50% at 50% 50%, #E39000 0%, #e36200 100%);
}

.home-title
{
    /* put on the top of the page */
    position: absolute;
    top: 100px;
    left: 50px;
    color: #fff;
    font-size: 24px;
}

.home-company-name
{
    display: flex;
    margin-top: 100px;
}

/* Reset and base styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #e36200;
}

/* Add padding to the body to create space around the content */
body {
    padding: 20px; /* Adjust this value as needed */
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* Navbar styles */
.navbar {
    display: flex;
    align-items: center;
    background-color: #e37d00;
    padding: 0 20px;
    height: 60px;
    border-radius: 5px; /* Optional: rounded corners */
}

.navbar-title {
    color: #fff;
    margin-right: auto;
}

.nav-links {
    display: flex;
    align-items: center;
}

.nav-link {
    color: #fff;
    margin-left: 20px;
    font-size: 16px;
}

.nav-link:hover {
    text-decoration: underline;
}

/* Container styles */
.container {
    padding: 20px;
}

/* Login page styles */

.login-background{
    background: radial-gradient(50% 50% at 50% 50%, #E39000 0%, #e36200 100%);
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container {
    width: 400px;
    height: 200px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: aliceblue;
    position: absolute;
    top: 15%;
}

.login-container h2 {
    margin-bottom: 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.login-input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
}

.login-button {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    background-color: #e36200;
    color: #fff;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.error-message {
    color: red;
    margin-bottom: 15px;
}

.analytics-container {
    display: flex;
}

.iframe-container {
    position: relative;
    width: 100%;
    height: 90vh;
}

.superset-iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.levels-container {
    padding: 20px;
}

.levels-container h1 {
    margin-bottom: 20px;
}