@import url(https://fonts.googleapis.com/css?family=Open+Sans:800);

.container {
  margin: auto;
  width: 400px;
}

.path--background {
  fill: rgb(255, 255, 255);
  stroke: #fff;
  stroke-width: 0px;
}

.pulse {
  fill: rgb(255, 74, 74) !important;
}

.path--foreground {
  fill: #eee;
  stroke: #eee;
  stroke-width: 2px;
}

.label {
  font: 90px "Open Sans";
  font-weight: 900;
  text-anchor: middle;
  fill: rgb(255, 255, 255);
}