.loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background: radial-gradient(50% 50% at 50% 50%, #E39000 0%, #e36200 100%);
}

.company-name {
    display: flex;
}

.bouncing-letter {
    object-fit: contain; /* Keeps the original aspect ratio */
    animation: bounce 1s infinite;
}

.loading-text {
    margin-top: 20px;
    font-size: 18px;
    color: #333;
}

@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
}

.days-input {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.days-input-field {
    padding: 50px;
    font-size: 4rem;
    border: 1px solid #ccc;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    outline: none;
    width: 400px;
    text-align: center;
}

.days-input-field:focus {
    border-color: orange;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

.days-submit-button {
    padding: 10px 20px;
    font-size: 4rem;
    color: white;
    background-color: orange;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    height: 180px;
}

.days-submit-button:hover {
    background-color: orangered;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.days-submit-button:focus {
    outline: none;
    box-shadow: 0 0 8px orangered;
}