
.filters-back {
    background-color: rgb(221, 221, 221);
    padding: 10px;
    border-radius: 5px;
}

.filter-group {
    margin-top: 10px;
}

.filter-box {
    display: block;
    margin-top: 10px;
}

.ticket-container {
    border-top: 3px solid black;
    margin-top: 20px;
    border-bottom: 3px solid black;
    padding-bottom: 20px;
}

.ticket-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.ticket-table th,
.ticket-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.ticket-table th {
    background-color: #f4f4f4;
}

.tag-container{
    margin-top: 20px;
    border-bottom: 3px solid black;
    padding-bottom: 20px;
}

.tag-usage-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
}

.tag-usage-period {
    flex: 1 1 30%;
    max-width: 30%;
    min-width: 250px; /* Ensures tables are responsive */
}

.tag-table {
    width: 100%;
    border-collapse: collapse;
}

.tag-table th, .tag-table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
}

.tag-table th {
    background-color: #f2f2f2;
}

.tag-usage-row {
    display: flex;
    justify-content: space-between;
}

.tag-usage-period {
    width: 30%; /* Adjust width as needed */
}

.filtered-count,
.total-count {
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Brighter background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the popup is above other elements */
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    max-height: 90vh; /* Ensure the popup doesn't exceed screen limits */    
}

.popup-ids {
    margin-top: 10px;
    max-height: 70vh; /* Adjust as needed */
    overflow-y: auto; /* Enable vertical scrolling within the popup content */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevents wrapping to a new line */
}

.popup-content h2 {
    margin: 0; /* Removes default margin from h2 */
    text-decoration: underline;
}

.popup-content ul {
    list-style-type: none;
    padding: 0;
}

.popup-content button {
    margin-top: 20px;
}

/* Exclude tags from popup styles */
.popup-content tag {
    background: none;
    padding: 0;
    border: none;
    box-shadow: none;
}

.popup-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-title button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 0;
    margin: 0; /* Removes default margin from button */
    font-weight: bold;
    color: #333; /* Color for the close button */
}

.popup-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.popup-table th, .popup-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: right; /* Right-align by default */
}

.popup-table th {
    text-align: center;
    background-color: #f4f4f4;
    font-weight: bold;
}

.popup-table th:nth-child(3){
    text-align: left;
}

.popup-table td {
    text-align: left; /* Left-align the "Tag" column */
    background-color: #ffffff;
}

.popup-table td:nth-child(2) {
    text-align: center; /* Center-align the "Days Ago" column */
}
.issue-view-container {
    margin-top: 20px;
    padding: 10px;
}

.search-input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    margin-top: 10px;
}

.search-terms-display {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.search-term {
    background-color: #f2f2f2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.search-term-key {
    padding-left: 10px;
    padding-right: 5px;
}

.remove-term-button {
    padding: 5px;
    border: none;
    color: black;
    cursor: pointer;
    display: flex;
    border-radius: 5px;
}

.remove-term-button:hover {
    border-radius: 5px;
    background-color: pink;
    color: red;
}

.term-count-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
}

.term-count-table th,
.term-count-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.term-count-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.term-count-table td {
    background-color: #ffffff;
}

.no-scroll {
    overflow: hidden;
}

.date-pickers {
    margin-left: 21vw;
    display: flex;
    gap: 10px;
    margin-top: 10px;
    gap: 13vw;
}

.zendesk-container
{
    background-color: white;
    padding: 20px;
}